import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { useMixpanel } from "gatsby-plugin-mixpanel"
import { MDXProvider } from "@mdx-js/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Box from "../components/box"
import Text from "../components/text"

const shortcodes = { Box, Text, Link }

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const featuredImagePublicURL = post.frontmatter.cover
    ? post.frontmatter.cover.childrenImageSharp[0].gatsbyImageData.images
        .fallback.src
    : ""
  const mixpanel = useMixpanel()

  return (
    <Layout title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        cover={featuredImagePublicURL}
      />
      <Box
        as="article"
        css={{
          maxWidth: "$maxW-lg",
          marginX: "auto",

          "@bp4": {
            maxWidth: "$maxW-md",
          },

          "@bp2": {
            maxWidth: "$maxW-sm",
          },
        }}
        itemScope
        itemType="http://schema.org/article"
      >
        <Box
          as="header"
          css={{
            marginY: "$64",
          }}
        >
          {/* inverted image */}

          {/* {post.frontmatter.cover && (
            <GatsbyImage
              image={getImage(post.frontmatter.cover.childrenImageSharp[0])}
              alt={`${post.frontmatter.title} cover`}
              style={{
                filter: "invert(1)",
                mixBlendMode: "lighten",
                maxWidth: "400px",
                marginTop: "$64",
              }}
            />
          )} */}

          {post.frontmatter.cover && (
            <GatsbyImage
              image={getImage(post.frontmatter.cover.childrenImageSharp[0])}
              alt={`${post.frontmatter.title} illustration`}
              className="acticle-cover"
            />
          )}

          <Box
            as={"h1"}
            css={{
              marginTop: "$24",
              marginBottom: post.frontmatter.description ? "$20" : "$8",
            }}
            itemProp="headline"
          >
            {post.frontmatter.title}
          </Box>
          <Box
            as="p"
            itemProp="headline"
            css={{
              fontSize: "$20",
            }}
          >
            {post.frontmatter.description && (
              <Text css={{ fontSize: "$20", lineHeight: "1.5" }}>
                {post.frontmatter.description}
              </Text>
            )}
            {post.frontmatter.publisher && (
              <Text
                css={{
                  fontSize: "$14",
                  lineHeight: "1.5",
                  color: "$text_tertiary",
                }}
              >
                Published in{" "}
                <a href={post.frontmatter.link}>{post.frontmatter.publisher}</a>
              </Text>
            )}
          </Box>
          {/* <Text css={{ fontSize: "$16", lineHeight: "1.5" }}>
            {post.frontmatter.date}
          </Text> */}
        </Box>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <hr />
      </Box>
      <nav>
        <Box
          as="ul"
          css={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,

            li: {
              fontFamily: "$sans",
              fontSize: "$14",
              fontWeight: "$600",
            },
          }}
        >
          <li>
            {previous && (
              <Link
                to={previous.fields.slug}
                rel="prev"
                onClick={() =>
                  mixpanel.track("Prev link", {
                    source: post.frontmatter.title,
                    to: previous.fields.slug,
                  })
                }
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.fields.slug}
                rel="next"
                onClick={() =>
                  mixpanel.track("Next link", {
                    source: post.frontmatter.title,
                    to: next.fields.slug,
                  })
                }
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </Box>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover {
          childrenImageSharp {
            gatsbyImageData(
              blurredOptions: { width: 240 }
              width: 1280
              placeholder: BLURRED
            )
          }
        }
        publisher
        link
      }
      headings {
        depth
        value
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
